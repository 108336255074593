<template>
  <div class="admin-login flow-bg white--text d-flex flex-column justify-center align-center pt-15">
    <img class="mb-10" src="@/assets/img/icon-admin.svg" alt="admin-logo" width="125px">
    <div class="admin-title rem-12 rem-md-24">TBT SHOP {{ $t('adminManagement') }}</div>
    <div class="login-block d-flex flex-column justify-center align-center w-full">
      <div class="rem-12 mb-10">{{ $t('loginYourAccount') }}</div>
      <textField dark :label="$t('enterAccount')"></textField>
      <textField dark textSwitch :label="$t('enterPassword')"></textField>
      <btn :buttonText="'login'" isBlock isGradient></btn>
    </div>
  </div>
</template>
<script>
import textField from '@/components/textField.vue'
import btn from '@/components/btn.vue'
export default {
  components:{
    textField,
    btn
  }
}
</script>
<style lang="scss">
.admin-login{
  .admin-title{
    margin-bottom: 80px;
  }
  .login-block{
    max-width: 330px;
    margin-bottom: 300px;
  }
}
</style>